<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    ΥΠΗΡΕΣΙΕΣ ΕΚΠΑΙΔΕΥΣΗΣ ΚΑΙ ΕΠΙΜΟΡΦΩΣΗΣ
                </div>
            </div>
        </div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    Η <span class="green-text">NUTRISENSE ΙΚΕ</span> διοργανώνει  επιμορφώσεις και σεμινάρια για την διαχείριση των υδροπονικών καλλιεργειών, καθώς και γενικότερα των θερμοκηπιακών καλλιεργειών, αλλά και των υπαίθριων καλλιεργειών κηπευτικών στο έδαφος, τα οποία απευθύνονται σε κάθε ενδιαφερόμενο. Παρέχονται επίσης εξειδικευμένα σεμινάρια σχετικά με την θρέψη και λίπανση των οπωροκηπευτικών και ανθοκομικών καλλιεργειών τόσο στο έδαφος όσο και σε υδροπονικά συστήματα. Επιπλέον, η <span class="green-text">NUTRISENSE ΙΚΕ</span> διοργανώνει εξειδικευμένα επιμορφωτικά σεμινάρια για τους εγγεγραμμένους συνδρομητές των εφαρμογών NutriSense (<span class="green-text">SOILLESS NutriSense</span> και <span class="green-text">SOIL NutriSense</span>), καθώς και για αυτούς που ενδιαφέρονται να εγγραφούν (χωρίς όμως να η συμμετοχή τους σε αυτά τα σεμινάρια να τους δεσμεύει να κάνουν εγγραφή στο <span class="green-text">NutriSense</span>).
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    Για περισσότερες πληροφορίες, επικοινωνήστε μαζί μας! <br>
                    Email: nutrisense@nutrisense.online <br>
                    Τηλ. <br> +30 694 836 8915 <br> +30 698 684 6648
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
</style>
